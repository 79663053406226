import { classNames } from "primereact/utils";
import { PropsWithChildren, useState, ReactElement, useId } from "react";
import { Button, ButtonProps } from "../Button";
import { Clickable, type ClickableProps } from "../Clickable";
import { Dropdown } from "../Dropdown";
import { List, ListItem } from "../List";
import styles from "./ActionDropdown.module.scss";
import { DropdownContext, useDropdownContext } from "./ActionDropdownContext";

const ActionDropdown = ({
  children,
  testId,
  ariaLabel,
  button,
}: PropsWithChildren<{ testId?: string; ariaLabel?: string; button?: ReactElement }>) => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const isHTMLElement = (target?: EventTarget | null): target is HTMLElement => !!target && "closest" in target;

  const closeDropdown = () => setDropdownOpen(false);
  const toggleDropdown = () => setDropdownOpen((prev) => !prev);
  const dropdownButtonId = useId();
  const handleClickOutside = ({ target }: KeyboardEvent | MouseEvent) => {
    // When clicking the button that opens the dropdown, we want to cancel the clickOutside event
    if (isHTMLElement(target) && target?.closest(`[data-dropdown-button="${dropdownButtonId}"]`)) return;

    closeDropdown();
  };

  return (
    <DropdownContext.Provider value={{ closeDropdown, toggleDropdown, dropdownButtonId }}>
      <div className={styles.actionDropdown}>
        {button ? (
          button
        ) : (
          <DropdownButton
            testId={testId && `${testId}-button`}
            iconProps={{ icon: "ellipsisVertical" }}
            variant="subtle"
            ariaLabel={ariaLabel}
          />
        )}
        <div className={styles.dropdownWrapper}>
          <Dropdown
            testId={testId && `${testId}-dropdown`}
            width="auto"
            isOpen={dropdownOpen}
            onClickOutside={handleClickOutside}
            onKeyupOutside={handleClickOutside}
          >
            <List>{children}</List>
          </Dropdown>
        </div>
      </div>
    </DropdownContext.Provider>
  );
};

type ActionProps = ClickableProps &
  PropsWithChildren<{
    className?: string;
    onClick?: () => void;
  }>;

const Action = ({ className, onClick, children, ...clickableProps }: ActionProps) => {
  const { closeDropdown } = useDropdownContext();
  const handleClick = () => {
    onClick?.();

    closeDropdown?.();
  };

  return (
    <ListItem>
      <Clickable
        testId="action-button"
        onClick={handleClick}
        className={classNames(className, styles.actionButton)}
        {...clickableProps}
      >
        {children}
      </Clickable>
    </ListItem>
  );
};

ActionDropdown.Action = Action;

const DropdownButton = ({ onClick, ...props }: ButtonProps) => {
  const { toggleDropdown, dropdownButtonId } = useDropdownContext();

  const handleClick = () => {
    toggleDropdown?.();
    onClick?.();
  };

  return <Button {...props} onClick={handleClick} data-dropdown-button={dropdownButtonId} />;
};

ActionDropdown.Button = DropdownButton;

export { ActionDropdown };
